import { composeActionEvent, composeScreenEvent } from './utils'

const commonParams = {
    screenClass: 'viat',
    tipoOperativa: 'viat',
    tipologia: 'movilidad',
    subtipologia: 'transaccional',
    seccion1: 'mis productos',
    seccion2: 'viat',
}

export const confirmedOrderPageName = 'pedido confirmado'
export const orderDetailPageName = 'resumen pedido'

// ------- STEP 1 ---------- //
export const showStep1View = composeScreenEvent({
    ...commonParams,
    screenName: 'viat datos personales',
    seccion3: 'datos personales',
})

export const startStep1Event = composeActionEvent({
    ...commonParams,
    screenName: 'viat datos personales',
    seccion3: 'datos personales',
    accionEvento: 'viat_inicio',
    etiquetaEvento: 'viat',
})

export const clickContinueButtonEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat datos personales',
    seccion3: 'datos personales',
    accionEvento: 'clic_continuar',
    etiquetaEvento: 'viat',
})

export const applyPromoCodeEvent = (stepName) => composeActionEvent({
    ...commonParams,
    screenName: 'viat ' + stepName,
    seccion3: stepName,
    accionEvento: 'cod_promocional',
    etiquetaEvento: 'aplicar promocion',
})

// ------- STEP 2 ---------- //
export const showStep2View = composeScreenEvent({
    ...commonParams,
    screenName: 'viat detalle de pago',
    seccion3: 'detalle de pago',
})

export const addCreditCardEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat detalle de pago',
    seccion3: 'detalle de pago',
    accionEvento: 'anadir_tarjeta',
    etiquetaEvento: 'viat',
})

export const addCreditCardBirthdayView = composeScreenEvent({
    ...commonParams,
    screenName: 'verificar tu edad',
    seccion3: 'verificar tu edad',
})

export const addCreditCardView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat detalle de pago anadir tarjeta',
    seccion3: 'detalle de pago anadir tarjeta',
})

export const addCreditCardOkEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat detalle de pago',
    seccion3: 'detalle de pago',
    accionEvento: 'anadir_tarjeta_ok',
    etiquetaEvento: 'viat',
})

export const addCreditCardKoEvent = composeScreenEvent({
    ...commonParams,
    screenName: 'viat detalle de pago anadir tarjeta ko',
    seccion3: 'anadir tarjeta ko',
})

export const addCreditCardFinishedView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat detalle de pago anadir tarjeta ok',
    seccion3: 'anadir tarjeta ok',
})

export const clickContinueButtonStep2Event = composeActionEvent({
    ...commonParams,
    screenName: 'viat detalle de pago anadir tarjeta ok',
    seccion3: 'datos personales',
    accionEvento: 'clic_continuar',
    etiquetaEvento: 'viat',
})

// ------- STEP 3 ---------- //
export const showStep3View = composeScreenEvent({
    ...commonParams,
    screenName: 'viat resumen pago',
    seccion3: 'resumen pago',
})

export const changeAddressButtonEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat resumen pago',
    seccion3: 'resumen pago',
    accionEvento: 'clic_cambiar',
    etiquetaEvento: 'detalle de envio',
})

export const changePaymentButtonEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat resumen pago',
    seccion3: 'resumen pago',
    accionEvento: 'clic_cambiar',
    etiquetaEvento: 'detalle de pago',
})

// ------- CONFIRMATION PAGE ---------- //
export const createNewOrderView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat pedido confirmado',
    seccion3: 'pedido confirmado ok',
})

export const createNewOrderEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat pedido confirmado',
    seccion3: 'pedido confirmado ok',
    accionEvento: 'viat_fin',
    etiquetaEvento: 'viat',
})

export const createNewOrderPaymentButtonClickEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat pedido confirmado',
    accionEvento: 'payment_button_click',
    etiquetaEvento: 'viat',
    seccion3: 'pedido confirmado ok',
})

export const createNewOrderKoView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat pedido confirmado ko',
    seccion3: 'pedido confirmado ko',
})

export const showNewOrderView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat resumen pedido',
    seccion3: 'resumen pedido',
})

export const showInvoiceButtonEvent = (pageName) => composeActionEvent({
    ...commonParams,
    screenName: `viat ${pageName}`,
    seccion3: `${pageName}`,
    accionEvento: 'descargar',
    etiquetaEvento: 'descargar factura viat',
})

// ------- GLOVIE ---------- //

export const myOrdersView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat mis pedidos',
    seccion3: 'mis pedidos',
})

export const myTollsView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat transitos',
    seccion3: 'transitos',
})

export const myMobesView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat mis viat',
    seccion3: 'mis viat',
})

export const cancelOrderEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat mis pedidos',
    seccion3: 'mis pedidos',
    accionEvento: 'cancelar_pedido',
    etiquetaEvento: 'cancelar pedido',
})

export const moreInfoButtonEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat mis pedidos',
    seccion3: 'mis pedidos',
    accionEvento: 'mas_informacion',
    etiquetaEvento: 'mas informacion',
})

export const myMobesHelpButtonEvent = composeActionEvent({
    ...commonParams,
    screenName: 'viat mis viat',
    seccion3: 'mis viat',
    accionEvento: 'problemas_viat',
    etiquetaEvento: 'problemas viat',
})
export const myMobesHelpButtonView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat podemos ayudarte',
    seccion3: 'podemos ayudarte',
})

export const myMobesHelpUnsubscribeView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat dar baja',
    seccion3: 'dar baja',
})
export const myMobesHelpUnsubscribeAction = composeActionEvent({
    ...commonParams,
    screenName: 'viat dar baja',
    seccion3: 'dar baja',
    accionEvento: 'baja_viat',
    etiquetaEvento: 'solicitar desactivacion',
})

export const myMobesHelpStolenView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat robo o perdida',
    seccion3: 'robo o perdida',
})

export const myMobesHelpStolenAction = composeActionEvent({
    ...commonParams,
    screenName: 'viat robo o perdida',
    seccion3: 'robo o perdida',
    accionEvento: 'baja_viat',
    etiquetaEvento: 'solicitar desactivacion',
})

export const myMobesHelpDoesNotWorkView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat no funciona',
    seccion3: 'no funciona',
})

export const myMobesHelpDoesNotWorkAction = composeActionEvent({
    ...commonParams,
    screenName: 'viat no funciona',
    seccion3: 'no funciona',
    accionEvento: 'baja_viat',
    etiquetaEvento: 'solicitar desactivacion',
})

export const parkingsByCountryView = composeScreenEvent({
    ...commonParams,
    screenName: 'viat lista parkings',
    seccion3: 'lista parkings',
})
export const trackParkingDetailByView = (parkingName) => composeScreenEvent({
    ...commonParams,
    screenName: 'viat detalle parking ' + parkingName,
    seccion3: 'detalle parking ' + parkingName,
})
