import { composeActionEvent, composeScreenEvent } from './utils'

const commonParams = {
    screenClass: 'mis productos',
    tipoOperativa: 'alquiler',
    tipologia: 'movilidad',
    subtipologia: 'transaccional',
    seccion1: 'mis productos',
    seccion2: 'alquiler',
    productosContratados: 'coche',
}

// ------- LANDING ---------- //
export const showLandingView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler landing',
    seccion3: 'landing',
})

export const clickSearchLink = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler landing',
    seccion3: 'landing',
    accionEvento: 'clic_buscar',
    etiquetaEvento: 'alquiler',
})

// ------- PROCESS ---------- //
export const showResultsView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler resultados',
    seccion3: 'resultados',
})

export const showMoreInfoPopUpView = (seller) =>
    composeScreenEvent({
        ...commonParams,
        screenName: 'alquiler info ' + seller,
        seccion3: 'info ' + seller,
    })

export const clickC2Clink = (view = 'resultados') =>
    composeActionEvent({
        ...commonParams,
        screenName: 'alquiler ' + view + ' c2c inicio',
        seccion3: view,
        accionEvento: 'c2c',
        etiquetaEvento: 'alquiler inicio',
    })
export const clickC2ClinkFromNoResults = composeActionEvent({
        ...commonParams,
        screenName: 'alquiler sin resultado',
        seccion3: 'sin resultados inicio',
        accionEvento: 'c2c',
        etiquetaEvento: 'alquiler inicio',
        IDEvento: 'te esta costando encontrar lo que buscas filtro inicio',
    })

export const applyFiltersLink = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler resultados',
    seccion3: 'resultados',
    accionEvento: 'aplicar_filtro',
    etiquetaEvento: 'alquiler',
})

export const showFilteredResultsView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler resultados filtro',
    seccion3: 'resultados filtro',
})

export const showNoResultsViews = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler sin resultado',
    seccion3: 'sin resultados',
})

export const cleanFiltersLink = (results) =>
    composeActionEvent({
        ...commonParams,
        screenName: results
            ? 'alquiler resultados filtro'
            : 'alquiler sin resultado',
        seccion3: results ? 'resultados filtro' : 'sin resultado',
        accionEvento: 'borrar_filtro',
        etiquetaEvento: 'alquiler',
    })

export const showExtrasView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler extras',
    seccion3: 'extras',
})

export const addExtraLink = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler extras',
    seccion3: 'resultados',
    accionEvento: 'anadir_extra',
    etiquetaEvento: 'alquiler',
})

export const showLoginView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler iniciar sesion',
    seccion3: 'iniciar sesion',
})

export const showOTPLoginView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler verificar identidad',
    seccion3: 'verificar identidad',
})

export const showUserDataView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler datos conductor',
    seccion3: 'datos conductor',
})

export const showConfirmBookingView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler confirmacion ok',
    seccion3: 'confirmacion ok',
})

export const showQRConfirmBookingView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler confirmacion qr',
    seccion3: 'confirmacion qr',
})

export const submitRentalCarBooking = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler confirmacion ok',
    seccion3: 'confirmacion ok',
    accionEvento: 'alquiler_fin',
    etiquetaEvento: 'alquiler fin',
})

export const modifyButtonRentalCarBooking = (pageName) => composeActionEvent({
    ...commonParams,
    screenName: `alquiler confirmacion ${pageName}`,
    seccion3: `confirmacion ${pageName}`,
    accionEvento: 'modif_reserva_alquiler',
    etiquetaEvento: 'modificar reserva alquiler',
})

export const cancelModifyButtonRentalCarBooking = (pageName) => composeActionEvent({
    ...commonParams,
    screenName: `alquiler necesitas modificar reserva ${pageName}`,
    seccion3: `confirmacion ${pageName}`,
    accionEvento: 'cancelar_reserva_alquiler',
    etiquetaEvento: 'cancelar reserva alquiler',
})

export const keepModifyButtonRentalCarBooking = (pageName) => composeActionEvent({
    ...commonParams,
    screenName: `alquiler necesitas modificar reserva ${pageName}`,
    seccion3: `confirmacion ${pageName}`,
    accionEvento: 'mantener_reserva_alquiler',
    etiquetaEvento: 'mantener reserva alquiler',
})

export const showModifyRentalCarBooking = (pageName) => composeScreenEvent({
    ...commonParams,
    screenName: `alquiler necesitas modificar reserva ${pageName}`,
    seccion3: 'modificar reserva',
})

export const ageSelectorCarBooking = (age) => composeActionEvent({
    ...commonParams,
    event: 'clic_edad',
    screenName: 'alquiler landing',
    accionEvento: 'clic_edad',
    etiquetaEvento: 'alquiler',
    edadDesplegable: age,
    seccion3: 'landing',
})

export const showC2CModal = (pageName) => composeScreenEvent({
    ...commonParams,
    screenName: `alquiler ${pageName} c2c inicio`,
    seccion3: `${pageName} c2c inicio`,
})

export const showC2cPhoneSent = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler resultados c2c gracias',
    seccion3: 'resultados c2c inicio',

})

export const showC2cPhoneSentFromNoResultsWithFilters = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler sin resultados filtro c2c gracias',
    seccion3: 'sin resultados filtro c2c gracias',

})

export const eventC2cPhoneSent = composeActionEvent({
    ...commonParams,
    event: 'c2c',
    screenName: 'alquiler resultados',
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler gracias',
    seccion3: 'resultados',
    IDEvento: 'necesitas info gracias',
})

export const eventC2cPhoneSentFromNoResultsWithFilters = composeActionEvent({
    ...commonParams,
    event: 'c2c',
    screenName: 'alquiler sin resultados filtro c2c gracias',
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler gracias',
    seccion3: 'sin resultados filtro c2c gracias',
    IDEvento: 'te esta costando encontrar lo que buscas filtro gracias',
})
export const showC2cInactivity = composeScreenEvent({
    ...commonParams,
    screenName: `resultados c2c inactividad inicio`,
    seccion3: `resultados c2c inactividad inicio`,

})

export const eventC2cInactivity = composeActionEvent({
    ...commonParams,
    event: 'c2c',
    screenName: `resultados c2c inactividad inicio`,
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler inicio',
    seccion3: 'resultados',
    IDEvento: 'inactividad inicio',
})

export const showC2cInactivityPhoneSent = composeScreenEvent({
    ...commonParams,
    screenName: `resultados c2c inactividad gracias`,
    seccion3: `resultados c2c inactividad gracias`,

})

export const eventC2cInactivityPhoneSent = composeActionEvent({
    ...commonParams,
    event: 'c2c',
    screenName: `resultados c2c inactividad gracias`,
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler gracias',
    seccion3: 'c2c inactividad gracias',
    IDEvento: 'inactividad gracias',
})

export const showSearchWithNoResults = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler sin resultados inicio',
    seccion3: 'sin resultados inicio',
})

export const eventSearchWithNoResults = composeActionEvent({
    ...commonParams,
    event: 'c2c',
    screenName: 'alquiler sin resultados inicio',
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler inicio',
    seccion3: 'sin resultados inicio',
    IDEvento: 'vaya no hay resultados inicio',
})

export const showC2cPhoneSentFromNoResults = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler sin resultado gracias',
    seccion3: 'sin resultado gracias',
})

export const eventC2cPhoneSentFromNoResults = composeActionEvent({
    ...commonParams,
    event: 'c2c',
    screenName: 'alquiler sin resultado gracias',
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler gracias',
    seccion3: 'sin resultado gracias',
    IDEvento: 'vaya no hay resultados gracias',
})

export const bookingConfirmPageName = 'ok'
export const bookingDetailPageName = 'detalle'

export const eventCancelBookingConfirmBooking = (screenName) => composeActionEvent({
    ...commonParams,
    event: 'cancelar_reserva_alquiler',
    screenName: `alquiler confirmacion ${screenName}`,
    seccion3: `confirmacion ${screenName}`,
    accionEvento: 'cancelar_reserva_alquiler',
    etiquetaEvento: 'cancelar reserva alquiler',
})

export const showCancelBookingConfirmBookingModal = (screenName) => composeScreenEvent({
    ...commonParams,
    screenName: `alquiler quieres cancelar reserva ${screenName}`,
    seccion3: 'cancelar reserva',
})

export const eventCancelBookingConfirmBookingModalCancel = (screenName) => composeActionEvent({
    ...commonParams,
    event: 'cancelar_reserva_alquiler',
    screenName: `alquiler quieres cancelar reserva ${screenName}`,
    seccion3: 'cancelar reserva',
    accionEvento: 'cancelar_reserva_alquiler',
    etiquetaEvento: 'cancelar reserva',
})

export const eventCancelBookingConfirmBookingModalClose = (screenName) => composeActionEvent({
    ...commonParams,
    event: 'mantener_reserva_alquiler',
    screenName: `alquiler quieres cancelar reserva ${screenName}`,
    seccion3: 'cancelar reserva',
    accionEvento: 'mantener_reserva_alquiler',
    etiquetaEvento: 'mantener reserva',
})

export const showBookingDetail = (screenName) => composeScreenEvent({
    ...commonParams,
    screenName: `alquiler confirmacion ${screenName}`,
    seccion3: `confirmacion ${screenName}`,
})

export const showRegisterView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler accede a tu cuenta',
    seccion3: 'accede a tu cuenta',
})

export const eventNoAccountButton = composeActionEvent({
    ...commonParams,
    screenName: 'alquiler accede a tu cuenta',
    seccion3: 'accede a tu cuenta',
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler inicio',
    IDEvento: 'no tienes cuenta mmas inicio',
})

export const showNoAccountC2CView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler accede a tu cuenta c2c inicio',
    seccion3: 'accede a tu cuenta c2c inicio',
})

export const noAccountC2CSentView = composeScreenEvent({
    ...commonParams,
    screenName: 'alquiler accede a tu cuenta c2c gracias',
    seccion3: 'accede a tu cuenta c2c gracias',
})

export const eventNoAccountC2CSent = composeActionEvent({
    ...commonParams,
    event: 'c2c',
    screenName: 'accede a tu cuenta gracias',
    seccion3: 'accede a tu cuenta c2c gracias',
    accionEvento: 'c2c',
    etiquetaEvento: 'alquiler gracias',
    IDEvento: 'accede a tu cuenta c2c gracias',
})
